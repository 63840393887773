import {customElement} from "solid-element";
import {setTranslations, translations} from "../../services/context";
import {getTranslations} from "../../services/translation";
import { For, Show, createSignal } from "solid-js";

// Insert WebComponent to Website Builder
// TAG: ba-templates-list
// var configBaTemplatesList = {
//     lang: 'en', // [de,en,es,fr,he,it,nl,pl,pt] languages
//     heightTemplatesBox: 'auto' // height to this webcomponent
// };
// ATTENTION: Se essa variável estiver descomentada os parâmetros do Website Builder não irão funcionar

document.addEventListener("DOMContentLoaded", function(event) {
    let baTagElement = document.querySelector('*[data-tag*="ba-templates-list"]');
    if(baTagElement){
        getTranslations(configBaTemplatesList.lang).then((r => setTranslations(r)));
        let webcomponent = document.createElement('builderall-templates-list');
        baTagElement.insertAdjacentElement('afterend', webcomponent);
        baTagElement.remove();
    }
});

const [loading, setLoading] = createSignal(false);
const [searchData, setSearchData] = createSignal(null);
const [templates, setTemplates] = createSignal([]);
const [templateType, setTemplateType] = createSignal('full_website');
const [templateIndustry, setTemplateIndustry] = createSignal(null);
const [showPremiumTemplates, setShowPremiumTemplates] = createSignal(true);

customElement("builderall-templates-list", props => {

    function getTemplatesList(){
        setLoading(true);
        // fetch(`${process.env.SITEBUILDER_API}/api/template/all`)
        fetch(`https://api-sitebuilder.kub.app.builderall.io/api/template/all`)
            .then(r => r.json())
            .then(response => {
                if(response.success){
                    const translatedTemplates = response.data.templates
                        .map(template => {
                            return {
                                ...template,
                                title: translations[template.title] || template.title
                            };
                        })
                        .sort((a, b) => a.title.localeCompare(b.title));

                    setTemplates(translatedTemplates);
                }
            })
            .catch(e => {
                console.error('Error to get templates list: ',e);
            })
            .finally(() => {
                setLoading(false);
            })
    }
    getTemplatesList();

    return (
        <>
            <style>{require("bundle-text:./styles/index.scss")}</style>
            <div class="templates-list">
                {/*@ts-ignore*/}
                <builderall-loader loading={loading()}>
                    <div class="templates-filters">
                        <div class="box-filter">
                            <div class="select-filter">
                                <label class="ba-custom-label" for="template_type">{translations['ct-template-website-type']}</label>
                                <select id="template_type" name="template_type" onChange={(e) => {setTemplateType(e.currentTarget.value);}}>
                                    <option value="">{translations['ct-all']}</option>
                                    <option value="full_website" selected>Full Website</option>
                                    <option value="full_funnel">Full Funnel</option>
                                </select>
                            </div>
                            <div class="select-filter">
                                <label class="ba-custom-label" for="industry">{translations['ct-template-industry']}</label>
                                <select id="industry" name="industry" onChange={(e) => {setTemplateIndustry(e.currentTarget.value);}}>
                                    <option value="" selected>{translations['ct-all']}</option>
                                    <option value="85">{translations['cheetah-category-template-85']}</option>
                                    <option value="86">{translations['cheetah-category-template-86']}</option>
                                    <option value="87">{translations['cheetah-category-template-87']}</option>
                                    <option value="88">{translations['cheetah-category-template-88']}</option>
                                    <option value="89">{translations['cheetah-category-template-89']}</option>
                                    <option value="90">{translations['cheetah-category-template-90']}</option>
                                    <option value="92">{translations['cheetah-category-template-92']}</option>
                                    <option value="94">{translations['cheetah-category-template-94']}</option>
                                    <option value="95">{translations['cheetah-category-template-95']}</option>
                                </select>
                            </div>
                            <div class="checkbox-premium-templates">
                                <input type="checkbox" id="show-premium" checked onInput={(e) => {setShowPremiumTemplates(e.currentTarget.checked);}}/>
                                <label for="show-premium">{translations['ct-show-premium-templates']}</label>
                            </div>
                        </div>
                        <div class="box-search">
                            <input type="search" value={searchData()} placeholder={translations['ct-search']} onInput={(e) => {setSearchData(e.currentTarget.value);}} />
                        </div>
                    </div>
                    <br />
                    <div class="templates-box" style={`max-height: ${configBaTemplatesList.heightTemplatesBox}`}>
                        <Show when={templates().filter(item => 
                                (!searchData() || item.title.toLowerCase().includes(searchData().toLowerCase())) && 
                                (!templateType() || templateType() == item.template_type) &&
                                (!templateIndustry() || templateIndustry() == item.category_id) &&
                                (!showPremiumTemplates() ? item.is_premium != 1 : true)
                            ).length > 0}
                            fallback={
                                <p>{translations['appshell-no-records-found']}</p>
                            }
                        >
                            <For each={templates().filter(item => 
                                (!searchData() || item.title.toLowerCase().includes(searchData().toLowerCase())) && 
                                (!templateType() || templateType() == item.template_type) &&
                                (!templateIndustry() || templateIndustry() == item.category_id) &&
                                (!showPremiumTemplates() ? item.is_premium != 1 : true)
                            )}>
                                    {(item) => (
                                        <>
                                            <Show when={true}>
                                                <div class="template">
                                                    <Show when={item.is_premium}>
                                                        <div class="premium-template-tab">
                                                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.73071 5.51258L6.49983 0.5L4.26895 5.51258L0 1.71188L1.76522 9.17118H11.2348L13 1.71188L8.73071 5.51258ZM1.76505 11.5H11.2346V10.1159H1.76505V11.5Z" fill="#FFCC3F"></path></svg>
                                                            <b>Premium</b>
                                                        </div>
                                                    </Show>
                                                    <div class="box-template-overlay">
                                                        <a
                                                            target="_blank"
                                                            v-if="is_wl"
                                                            href={`https://${item.is_premium ? 'cheetah-premium-template-' : 'cheetah-template-'}${item.id}.template.builderall.com`}
                                                            class="overlay-preview-button"
                                                        >
                                                            {translations['ct-pre-view']}
                                                        </a>
                                                        <Show 
                                                            when={item.is_premium}
                                                            fallback={
                                                                <a href="https://checkout.builderall.com/plans" class="overlay-get-started-button">
                                                                    {translations['ct-save-template-btn']}
                                                                </a>
                                                            }>
                                                            <a href={`https://checkout.builderall.com/payment/premium-template?tp_slug=${item.title}`} class="overlay-warning-button">
                                                                {translations['ct-buy']}
                                                            </a>
                                                        </Show>
                                                    </div>
                                                    {/* <div class="template-thumb" style={`background-image: url(${process.env.SITEBUILDER_STORAGE}/${item.thumbnail})`}></div> */}
                                                    <div class="template-thumb" style={`background-image: url(https://cheetah-templates.builderall.com/storage/${item.thumbnail})`}></div>
                                                    <div class="template-info">
                                                        <span class="template-title">{item.title}</span>
                                                        <Show when={item.is_premium == 1}>
                                                            <span class="template-amount">${item.amount}</span>
                                                        </Show>
                                                    </div>
                                                </div>
                                            </Show>
                                        </>
                                    )}
                            </For>
                        </Show>
                    </div>
                {/*@ts-ignore*/}
                </builderall-loader>
            </div>
        </>
    );
});
